import * as client_hooks from '../../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/account": [5],
		"/admin": [6],
		"/admin/accounts": [7],
		"/admin/audit/[...type]": [8],
		"/admin/companies": [9],
		"/admin/companies/[...company]/backups": [10,[2]],
		"/admin/companies/[...company]/installations": [11,[2]],
		"/admin/companies/[...company]/licenses": [12,[2]],
		"/admin/companies/[...company]/persons": [13,[2]],
		"/admin/images": [14],
		"/admin/tailscale": [15],
		"/company": [16],
		"/installations": [17],
		"/installations/[...installation]/backups": [18,[3]],
		"/login": [19],
		"/oidc": [20]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';